// import { axios as request, get, post } from '@/utils/request'

import { axios as request, post, get } from '@/utils/request'
// import defaultSettings from '@/config/defaultSettings'

// ===================================公共================================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-15 16:23:25
 * @Desc: 图片上传
 */
export const upload = (params, config = {}) => request.post('/app/uploads', params, { ...config, upload: true })
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-23 09:24:23
 * @Desc: 获取省市区数据
 */
// export const getDistrict = () => request.get(`/amap/v3/config/district?subdistrict=3&key=${defaultSettings.webkey}`)
export const getDistrict = () => request.get(`/api/city/list`)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-25 15:27:26
 * @Desc:  证件识别
 */
export const getIdCardInfo = (params, config = {}) => post('/api/baidu/getIdCardInfo', params, { ...config })

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-25 18:11:57
 * @Desc:  通用导出下载接口
 */
export const commonDownload = (params, config = {}) => request.get('/api/localDownload', { params, ...config })
/**
 * javascript comment
 * @Author: flydreame
 * @Date: 2023-09-28 11:45:48
 * @Desc:  文件下载
 */
export const appDownload = (params, config = {}) => request.get('/pc/download', { params, ...config })
/**
 * javascript comment
 * @Author: flydreame
 * @Date: 2023-09-28 11:45:48
 * @Desc:  文件下载
 */
export const appDownloadByPath = (params, config = {}) => request.get('/app/download', { params, ...config })
/**
 * javascript comment
 * @Author: flydreame
 * @Date: 2023-09-28 11:45:48
 * @Desc:  文件夹下载
 */
export const downloadFolder = (params, config = {}) => request.get('/pc/downloadFolder', { params, ...config })

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-08 18:00:59
 * @Desc: 获取字典参数
 * @param {string} dictType
 */
export const dictDataByType = (params, config = {}) => get('/api/company/dictDataListByDictType', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-08 18:00:59
 * @Desc: 发送验证码
 */
export const sendSMS = (params, config = {}) => post('/api/sms/sendSMS', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-08 18:00:59
 * @Desc: 修改密码
 */
export const editPassword = (params, config = {}) => post('/api/login/editPassword', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/16 18:58:50
 * @description 更换手机号提交
 */
export const ApiPostLoginEditPhoneSub = (params, config = {}) => post('/api/login/editPhoneSub', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/14 17:43:25
 * @description 查询所有部门所有人员
 */
export const ApiGetCompanyLserlist = (params) => get('/api/company/userlist', params)

/**
 * @Author: wxz
 * @Date: 2023/12/23 20:06:31
 * @description 所有城市信息（树状结构 省市区）
 */
export const ApiGetCityListAllToTree = (params) => get('/api/city/listAllToTree', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 11:13:26
 * @description 查询客户列表
 */
export const ApiGetCompanyClientList = (params) => get('/api/company/clientList', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 11:56:01
 * @description 客户车间列表 无分页
 */
export const ApiGetCompanyWorkshopList = (params) => get('/api/company/workshopList', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 16:22:31
 * @description 获取库存表所有型号
 */
export const ApiGetCompanyStockList = (params) => get('/api/company/stockList', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 16:49:00
 * @description 查询合同表所有的合同编码
 */
export const ApiGetCompanyContractNumberList = (params) => get('/api/company/contractNumberList', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 16:54:46
 * @description 根据客户id查询合同编码 有分页
 */
export const ApiGetCompanyContractNumberListPage = (params) => get('/api/company/contractNumberListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/8 11:56:57
 * @description 根据合同编码查询供应商id
 */
export const ApiGetCompanySupplierList = (params) => get('/api/company/supplierList', params)

/**
 * @Author: wxz
 * @Date: 2024/1/17 11:19:22
 * @description 修改用户信息
 */
export const ApiPostLoginUpdateAccount = (params, config = {}) => post('/api/login/updateAccount', params, config)

// ===================================导出================================================
// commonDownload
//  /api/warehouseDetail/otherExport  入库记录导出  warehouseDetailIds
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 询价导出
 */
export const ApiGetEnquiryExport = (params) => get('/api/enquiry/export', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 客户导出
 */
export const ApiGetClientRecordExport = (params) => get('/api/clientRecord/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 采购询价导出
 */
export const ApiGetPurchaseEnquiryExport = (params) => get('/api/enquiry/purchaseEnquiryExport', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 导出报价数据
 */
export const ApiGetQuotationExport = (params) => get('/api/quotation/quotationExport', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 合同数据导出
 */
export const ApiGetContractExport = (params) => get('/api/contract/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 待采购记录导出
 */
export const ApiGetPurchaseSingleExport = (params) => get('/api/purchaseSingle/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 采购记录导出
 */
export const ApiGetPurchaseExport = (params) => get('/api/purchase/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 供应商导出
 */
export const ApiGetSupplierManageExport = (params) => get('/api/supplierManage/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 销售数据导出
 */
export const ApiGetSalesExport = (params) => get('/api/sales/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 入库记录导出
 */
export const ApiGetWarehouseDetailExport = (params) => get('/api/warehouseDetail/otherExport', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 出库数据导出
 */
export const ApiGetLeaveStoreroomExport = (params) => get('/api/leaveStoreroom/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 库存数据导出
 */
export const ApiGetStockExport = (params) => get('/api/stock/export', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 考勤数据导出
 */
export const ApiGetClockingExport = (params) => get('/api/clocking/export', params)
/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 销售历史导出
 */
export const ApiGetExportSalesHistory = (params) => get('/api/sales/exportSalesHistory', params)

// ===================================工作台================================================

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:14:48
 * @description 数据看板
 */
export const ApiGetIndexDataBoard = (params) => get('/api/index/dataBoard', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:15:06
 * @description 客户成交数据
 */
export const ApiGetIndexClientTurnover = (params) => get('/api/index/clientTurnover', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:15:21
 * @description 通知公告 加分页
 */
export const ApiGetIndexMessageRecord = (params) => get('/api/index/messageRecord', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:15:36
 * @description 产品销量
 */
export const ApiGetIndexProductSales = (params) => get('/api/index/productSales', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:15:51
 * @description 最新提交合同 加分页
 */
export const ApiGetIndexSubmitContract = (params) => get('/api/index/submitContract', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 00:16:13
 * @description 库房库存量
 */
export const ApiGetIndexStockSum = (params) => get('/api/index/stockSum', params)

// ===================================客户管理-客户信息================================================

/**
 * @Author: wxz
 * @Date: 2023/12/26 16:23:19
 * @description  客户量统计
 */
export const ApiGetClientRecordStatistics = (params) => get('/api/clientRecord/statistics', params)

/**
 * @Author: wxz
 * @Date: 2023/12/23 18:47:32
 * @description 客户列表
 */
export const ApiGetClientRecordListPage = (params) => get('/api/clientRecord/listPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/23 18:47:45
 * @description 新增客户
 */
export const ApiPostClientRecordAdd = (params, config = {}) => post('/api/clientRecord/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/23 18:47:58
 * @description 修改客户
 */
export const ApiPostClientRecordUpdate = (params, config = {}) => post('/api/clientRecord/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/23 18:48:07
 * @description 删除客户
 */
export const ApiPostClientRecordDeletes = (params, config = {}) => post('/api/clientRecord/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/23 18:48:26
 * @description 客户详情 用户修改回显
 */
export const ApiGetClientRecordDetails = (params) => get('/api/clientRecord/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/23 18:48:36
 * @description 批量修改业务员
 */
export const ApiPostClientRecordUpdateUserId = (params, config = {}) =>
  post('/api/clientRecord/updateUserId', params, config)

// ===================================客户管理-客户跟进记录================================================

/**
 * @Author: wxz
 * @Date: 2023/12/25 14:00:04
 * @description 客户跟进记录列表
 */
export const ApiGetFollowUpRecordLlientFollowUpRecordList = (params) =>
  get('/api/followUpRecord/clientFollowUpRecordList', params)

/**
 * @Author: wxz
 * @Date: 2023/12/25 14:00:28
 * @description 新增跟进记录
 */
export const ApiPostFollowUpRecordAdd = (params, config = {}) => post('/api/followUpRecord/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 14:00:46
 * @description 修改跟进记录
 */
export const ApiPostFollowUpRecordUpdate = (params, config = {}) => post('/api/followUpRecord/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 14:01:00
 * @description 删除跟进记录
 */
export const ApiPostFollowUpRecordDeletes = (params, config = {}) => post('/api/followUpRecord/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 14:01:09
 * @description 跟进记录详情 用于修改回显
 */
export const ApiGetFollowUpRecordNaNetails = (params) => get('/api/followUpRecord/details', params)

// ===================================客户管理-车间相关================================================

/**
 * @Author: wxz
 * @Date: 2023/12/25 15:32:19
 * @description 添加客户车间
 */
export const ApiPostClientWorkshopAdd = (params, config = {}) => post('/api/clientWorkshop/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 15:32:34
 * @description 客户车间首页(包含所有页面)
 */
export const ApiGetClientWorkshopNaNomePage = (params) => get('/api/clientWorkshop/homePage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/25 15:32:45
 * @description 修改客户车间
 */
export const ApiPostClientWorkshopUpdate = (params, config = {}) => post('/api/clientWorkshop/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 15:32:55
 * @description 删除客户车间
 */
export const ApiPostClientWorkshopDeletes = (params, config = {}) => post('/api/clientWorkshop/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 15:33:04
 * @description 客户车间详情 用户修改时回显数据
 */
export const ApiGetClientWorkshopDetails = (params) => get('/api/clientWorkshop/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/25 15:33:15
 * @description 车间列表
 */
export const ApiGetFollowUpRecordLlientWorkshopMapList = (params) =>
  get('/api/followUpRecord/clientWorkshopMapList', params)

// ===================================客户车间联系人================================================

/**
 * @Author: wxz
 * @Date: 2023/12/25 17:16:50
 * @description 车间联系人列表
 */
export const ApiGetWorkshopContactsList = (params) => get('/api/workshopContacts/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/25 17:17:21
 * @description 新增车间联系人
 */
export const ApiPostWorkshopContactsAdd = (params, config = {}) => post('/api/workshopContacts/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 17:17:42
 * @description 修改车间联系人
 */
export const ApiPostWorkshopContactsUpdate = (params, config = {}) =>
  post('/api/workshopContacts/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 17:17:53
 * @description 修改车间联系人备注
 */
export const ApiPostWorkshopContactsUpdateRemarks = (params, config = {}) =>
  post('/api/workshopContacts/updateRemarks', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 17:18:02
 * @description 删除车间联系人
 */
export const ApiPostWorkshopContactsDeletes = (params, config = {}) =>
  post('/api/workshopContacts/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/25 17:18:11
 * @description 车间联系人详情
 */
export const ApiGetWorkshopContactsDetails = (params) => get('/api/workshopContacts/details', params)

// ===================================车间设备================================================

/**
 * @Author: wxz
 * @Date: 2023/12/25 16:31:53
 * @description 车间设备列表
 */
export const ApiGetClientWorkshopClientWorkshopDetails = (params) =>
  get('/api/clientWorkshop/clientWorkshopDetails', params)

/**
 * @Author: wxz
 * @Date: 2023/12/26 09:47:18
 * @description 新增车间设备
 */
export const ApiPostWorkshopFacilityAdd = (params, config = {}) => post('/api/workshopFacility/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 09:48:35
 * @description 修改车间设备
 */
export const ApiPostWorkshopFacilityUpdate = (params, config = {}) =>
  post('/api/workshopFacility/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 09:48:51
 * @description 删除车间设备
 */
export const ApiPostWorkshopFacilityDeletes = (params, config = {}) =>
  post('/api/workshopFacility/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 09:49:04
 * @description 车间设备详情 用户修改回显
 */
export const ApiGetWorkshopFacilityDetails = (params) => get('/api/workshopFacility/details', params)

/**
 * @Author: wxz
 * @Date: 2024/3/14 15:44:54
 * @description 添加车间设备文件
 */
export const ApiPostWorkshopFacilityFileAdd = (params, config = {}) =>
  post('/api/workshopFacility/fileAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/14 15:45:09
 * @description 删除车间设备文件
 */
export const ApiPostWorkshopFacilityFileDel = (params, config = {}) =>
  post('/api/workshopFacility/fileDel', params, config)

// ===================================设备型号================================================

/**
 * @Author: wxz
 * @Date: 2023/12/26 10:41:08
 * @description 新增设备部件
 */
export const ApiPostFacilityPartsAdd = (params, config = {}) => post('/api/facilityParts/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 10:41:21
 * @description 修改设备部件
 */
export const ApiPostFacilityPartsUpdate = (params, config = {}) => post('/api/facilityParts/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 10:41:31
 * @description 删除设备部件
 */
export const ApiPostFacilityPartsDeletes = (params, config = {}) => post('/api/facilityParts/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 10:41:45
 * @description 设备部件详情 用于数据修改回显
 */
export const ApiGetFacilityPartsDetails = (params) => get('/api/facilityParts/details', params)

// ===================================客户询价================================================

/**
 * @Author: wxz
 * @Date: 2023/12/27 10:10:39
 * @description 客户询价列表（分页）
 */
export const ApiGetEnquiryClientListPage = (params) => get('/api/enquiry/clientListPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 10:12:04
 * @description 往期数据列表（分页）
 */
export const ApiGetEnquiryPreviousPage = (params) => get('/api/enquiry/previousPage', params)

/**
 * @Author: wxz
 * @Date: 2024/3/15 09:28:20
 * @description 添加往期备注
 */
export const ApiPostEnquiryAddRemark = (params, config = {}) => post('/api/enquiry/addRemark', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 10:12:23
 * @description 客户询价详情
 */
export const ApiGetEnquiryClientDetails = (params) => get('/api/enquiry/clientDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:36:58
 * @description 询价导入
 */
export const ApiPostEnquiryImportData = (params, config = {}) => post('/api/enquiry/importData', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 10:12:45
 * @description 获取库存信息
 */
export const ApiGetEnquiryRepertoryInfo = (params) => get('/api/enquiry/repertoryInfo', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 10:12:45
 * @description 获取库房列表
 */
export const ApiGetCompanyStoreroomList = (params) => get('/api/company/storeroomList', params)
/**
 * @Author: wxz
 * @Date: 2023/12/27 10:12:45
 * @description 获取货位列表
 */
export const ApiGetCompanyStockSpaceList = (params) => get('/api/company/stockSpaceList', params)
/**
 * @Author: wxz
 * @Date: 2023/12/27 10:13:00
 * @description 客户新增询价（json）
 */
export const ApiPostEnquiryClientAdd = (params, config = {}) => post('/api/enquiry/clientAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 10:13:17
 * @description 客户修改询价（json） 已发采购询价的部分不能修改 需求数量 物料单位 报价型号
 */
export const ApiPostEnquiryClientUpdate = (params, config = {}) => post('/api/enquiry/clientUpdate', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:32:09
 * @description 通过询价ID获取报价信息
 */
export const ApiGetQuotationGetQuotationInfo = (params) => get('/api/quotation/getQuotationInfo', params)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:32:09
 * @description 根据供应商和产品查询品牌
 */
export const ApiGetSupplierManageListBrand = (params) => get('/api/supplierManage/listBrand', params)

// ===================================客户报价管理================================================

/**
 * @Author: wxz
 * @Date: 2024/1/5 21:25:36
 * @description 通过报价详情ID 获取发起用户 审核用户 抄送用户信息
 */
export const ApiGetQuotationCheckUserInfo = (params) => get('/api/quotation/checkUserInfo', params)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:42:25
 * @description 提交报价审批(json)
 */
export const ApiPostQuotationAdd = (params, config = {}) => post('/api/quotation/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:42:38
 * @description 修改报价审批(json) 只有在待审批或者已驳回或者已撤回的状态下才能修改
 */
export const ApiPostQuotationUpdate = (params, config = {}) => post('/api/quotation/update', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:42:52
 * @description 删除报价审批
 */
export const ApiPostQuotationDeletes = (params, config = {}) => post('/api/quotation/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:43:24
 * @description 我发起的报价撤回（只有在待审批的状态下才能撤回）
 */
export const ApiPostQuotationInitiateRevocation = (params, config = {}) =>
  post('/api/quotation/initiateRevocation', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:41:26
 * @description 我发起的审批列表
 */
export const ApiGetQuotationMyInitiateListPage = (params) => get('/api/quotation/myInitiateListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/5 17:41:55
 * @description 我发起的审批详情
 */
export const ApiGetQuotationDetails = (params) => get('/api/quotation/details', params)

// ==============审核====
/**
 * @Author: wxz
 * @Date: 2024/1/5 22:20:18
 * @description 我审批的审批列表
 */
export const ApiGetQuotationMyExamineListPage = (params) => get('/api/quotation/myExamineListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/5 22:20:42
 * @description 我审批详情
 */
export const ApiGetQuotationMyExamineDetails = (params) => get('/api/quotation/myExamineDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/1/5 22:21:00
 * @description 我审批的审批提交 通过/拒绝
 */
export const ApiPostQuotationExamineSub = (params, config = {}) => post('/api/quotation/examineSub', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/17 14:28:37
 * @description 抄送我的审批列表
 */
export const ApiGetQuotationCopyMyListPage = (params) => get('/api/quotation/copyMyListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/17 14:28:54
 * @description 抄送我的详情
 */
export const ApiGetQuotationCopyMyDetails = (params) => get('/api/quotation/copyMyDetails', params)

// ====================================客户管理-开票记录================================================

/**
 * @Author: wxz
 * @Date: 2024/1/11 21:58:33
 * @description 出项开票列表 分页
 */
export const ApiGetExitInvoiceList = (params) => get('/api/exitInvoice/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/11 22:01:45
 * @description 出项开票详情
 */
export const ApiGetExitInvoiceDetails = (params) => get('/api/exitInvoice/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/11 22:03:03
 * @description 客户列表申请开票新增
 */
export const ApiPostExitInvoiceApply = (params, config = {}) => post('/api/exitInvoice/apply', params, config)
/**
 * @Author: wxz
 * @Date: 2024/1/11 22:03:03
 * @description 客户列表申请开票修改
 */
export const ApiPostExitInvoiceEdit = (params, config = {}) => post('/api/exitInvoice/edit', params, config)
/**
 * @Author: wxz
 * @Date: 2024/1/10 20:58:02
 * @description 根据购货单位（供货商）和合同编码查询销售单商品
 */
export const ApiGetPurchaseInvoiceList = (params) => get('/api/purchase/invoiceList', params)

/**
 * @Author: wxz
 * @Date: 2024/1/11 22:03:32
 * @description 根据合同编码查询合同列表 分页
 */
export const ApiGetExitInvoiceNaNroductList = (params) => get('/api/exitInvoice/productList', params)
/**
 * @Author: wxz
 * @Date: 2024/1/11 22:01:45
 * @description 进项开票详情
 */
export const ApiGetEnterInvoiceDetails = (params) => get('/api/enterInvoice/details', params)
/**
 * @Author: wxz
 * @Date: 2024/1/11 22:03:43
 * @description 进项发票确认开票-审核
 */
export const ApiGetExitInvoiceAudit = (params) => get('/api/exitInvoice/audit', params)

/**
 * @Author: wxz
 * @Date: 2024/1/11 22:03:54
 * @description 本月开票总额度修改
 */
export const ApiPostExitInvoiceMoneyEdit = (params, config = {}) => post('/api/exitInvoice/moneyEdit', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/12 18:27:07
 * @description 申请开票(进项发票)
 */
export const ApiGetPurchaseApply = (params, config = {}) => post('/api/purchase/apply', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/12 18:28:48
 * @description 进项发票列表(进项发票)
 */
export const ApiGetEnterInvoiceList = (params) => get('/api/enterInvoice/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/12 18:29:40
 * @description 确认开票(进项发票)
 */
export const ApiGetEnterInvoiceAudit = (params) => get('/api/enterInvoice/audit', params)

/**
 * @Author: wxz
 * @Date: 2024/1/12 18:28:48
 * @description 非业务发票列表
 */
export const ApiGetSalesmanInvoiceList = (params) => get('/api/salesmanInvoice/list', params)
/**
 * @Author: wxz
 * @Date: 2024/1/12 18:27:07
 * @description 非业务发票-确认收票
 */
export const ApiPostSalesmanInvoice = (params, config = {}) => post('/api/salesmanInvoice/audit', params, config)
// ===================================合同管理================================================

/**
 * @Author: wxz
 * @Date: 2024/1/10 14:30:49
 * @description 成交记录列表
 */
export const ApiGetFinishRecordList = (params) => get('/api/finishRecord/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 14:31:56
 * @description 修改车间
 */
export const ApiPostFinishRecordEdit = (params, config = {}) => post('/api/finishRecord/edit', params, config)

// ===================================合同管理================================================
/**
 * @Author: wxz
 * @Date: 2024/1/6 16:25:16
 * @description 通过合同详情ID 获取发起用户 审核用户 抄送用户信息
 */
export const ApiGetContractCheckUserInfo = (params) => get('/api/contract/checkUserInfo', params)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:22:14
 * @description 提交合同审批(json)
 */
export const ApiPostContractAdd = (params, config = {}) => post('/api/contract/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:22:33
 * @description 我发起的合同列表
 */
export const ApiGetContractMyInitiateListPage = (params) => get('/api/contract/myInitiateListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:22:46
 * @description 合同详情
 */
export const ApiGetContractDetails = (params) => get('/api/contract/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:23:18
 * @description 修改报价审批(json) 只有在待审批或者已驳回或者已撤回的状态下才能修改
 */
export const ApiPostContractUpdate = (params, config = {}) => post('/api/contract/update', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:23:31
 * @description 删除合同信息
 */
export const ApiPostContractDeletes = (params, config = {}) => post('/api/contract/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/6 21:36:26
 * @description 获取采购单详情，合同生成采购单
 */
export const ApiGetContractSalesList = (params) => get('/api/contract/salesList', params)

// /**
//  * @Author: wxz
//  * @Date: 2024/1/6 21:38:01
//  * @description 提交采购审批（json）
//  */
// export const ApiPostPurchaseAdd = (params, config = {}) => post('/api/purchase/add', params, config)

// ==============我审批的===============
/**
 * @Author: wxz
 * @Date: 2024/1/6 16:24:02
 * @description 我审批列表
 */
export const ApiGetContractMyExamineListPage = (params) => get('/api/contract/myExamineListPage', params)
/**
 * @Author: wxz
 * @Date: 2024/1/6 16:23:48
 * @description 我审批详情
 */
export const ApiGetContractMyExamineDetails = (params) => get('/api/contract/myExamineDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:24:21
 * @description 我审批的审批提交 通过/拒绝
 */
export const ApiPostContractExamineSub = (params, config = {}) => post('/api/contract/examineSub', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:24:46
 * @description 抄送我的 列表
 */
export const ApiGetContractCopyMyListPage = (params) => get('/api/contract/copyMyListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/6 16:25:00
 * @description 抄送我的详情
 */
export const ApiGetContractCopyMyDetails = (params) => get('/api/contract/copyMyDetails', params)

// ===================================采购询价================================================

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:17:30
 * @description 通过采购详情ID 获取发起用户 审核用户 抄送用户信息
 */
export const ApiGetPurchaseCheckUserInfo = (params) => get('/api/purchase/checkUserInfo', params)

/**
 * @Author: wxz
 * @Date: 2024/1/8 12:06:36
 * @description 根据用户合同编码供应商查询需采购的列表
 */
export const ApiGetPurchaseList = (params) => get('/api/purchase/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:16:03
 * @description 提交采购审批（json）
 */
export const ApiPostPurchaseAdd = (params, config = {}) => post('/api/purchase/add', params, config)

// ========待采购==========
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2024-04-28 18:04:03
 * @Desc: 待采购列表
 */
export const ApiGetPurchaseSingleList = (params) => get('/api/purchaseSingle/list', params)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2024-04-28 18:04:03
 * @Desc: 待采购列表详情
 */
export const ApiGetPurchaseSingleDetail = (params) => get('/api/purchaseSingle/detail', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:16:03
 * @description 采购不到
 */
export const ApiPostPurchaseSingleEditStatus = (params, config = {}) =>
  post('/api/purchaseSingle/editStatus', params, config)

// ========我提交===========
/**
 * @Author: wxz
 * @Date: 2024/1/7 10:14:29
 * @description 我发起的审批列表
 */
export const ApiGetPurchaseMyInitiateListPage = (params) => get('/api/purchase/myInitiateListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:15:19
 * @description 采购详情
 */
export const ApiGetPurchaseDetails = (params) => get('/api/purchase/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:17:08
 * @description 我发起的采购撤回（只有在待审批的状态下才能撤回）
 */
export const ApiPostPurchaseInitiateRevocation = (params, config = {}) =>
  post('/api/purchase/initiateRevocation', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:16:41
 * @description 修改采购审批（json）只有在待审批或者已驳回或者已撤回的状态下才能修改
 */
export const ApiPostPurchaseUpdate = (params, config = {}) => post('/api/purchase/update', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:16:55
 * @description 删除采购审批
 */
export const ApiPostPurchaseDeletes = (params, config = {}) => post('/api/purchase/deletes', params, config)

// ==========我审核的=============
/**
 * @Author: wxz
 * @Date: 2024/1/7 10:17:56
 * @description 我审批列表
 */
export const ApiGetPurchaseMyExamineListPage = (params) => get('/api/purchase/myExamineListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:18:31
 * @description 我审批详情
 */
export const ApiGetPurchaseMyExamineDetails = (params) => get('/api/purchase/myExamineDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 10:19:17
 * @description 我审批的审批提交 通过/拒绝
 */
export const ApiPostPurchaseExamineSub = (params, config = {}) => post('/api/purchase/examineSub', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/17 21:00:51
 * @description 抄送我的采购列表
 */
export const ApiGetPurchaseCopyMyListPage = (params) => get('/api/purchase/copyMyListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/17 21:01:21
 * @description 抄送我的详情
 */
export const ApiGetPurchaseCopyMyDetails = (params) => get('/api/purchase/copyMyDetails', params)

// ==========

/**
 * @Author: wxz
 * @Date: 2024/1/3 09:40:23
 * @description 采购询价列表（分页）
 */
export const ApiGetEnquiryPurchaseListPage = (params) => get('/api/enquiry/purchaseListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/3 09:41:06
 * @description 采购询价详情
 */
export const ApiGetEnquiryPurchaseDetails = (params) => get('/api/enquiry/purchaseDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/1/3 09:41:31
 * @description 采购修改询价（json）   已提交的询价的部分不能修改
 */
export const ApiPostEnquiryPurchaseUpdate = (params, config = {}) => post('/api/enquiry/purchaseUpdate', params, config)
/**
 * @Author: wxz
 * @Date: 2024/1/3 09:41:31
 * @description 采购询价 询不到
 */
export const ApiPostEnquiryNoEnquiry = (params, config = {}) => post('/api/enquiry/noEnquiry', params, config)
/**
 * @Author: wxz
 * @Date: 2024/1/9 16:27:06
 * @description 采购申请付款 添加付款记录
 */
export const ApiPostPaymentAudAdd = (params, config = {}) => post('/api/payment/audAdd', params, config)

// ==================================采购询价-供应商产品================================================

/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款我提交的
 */
export const ApiGetPaymentMyInitiateListPage = (params) => get('/api/payment/myInitiateListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款我提交的详情
 */
export const ApiGetPaymentMyInitiateDetails = (params) => get('/api/payment/myInitiateDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款我审核的
 */
export const ApiGetPaymentMyExamineListPage = (params) => get('/api/payment/myExamineListPage', params)
/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款我审核的详情
 */
export const ApiGetPaymentMyExamineDetails = (params) => get('/api/payment/myExamineDetails', params)
/**
 * @Author: wxz
 * @Date: 2024/1/9 16:27:06
 * @description 采购申请付款审核
 */
export const ApiPostPaymentMyAudit = (params, config = {}) => post('/api/payment/myAudit', params, config)
/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款抄送我的
 */
export const ApiGetPaymentCopyMyListPage = (params) => get('/api/payment/copyMyListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款抄送我的详情
 */
export const ApiGetPaymentCopyMyDetails = (params) => get('/api/payment/copyMyDetails', params)
/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 采购付款 审核人
 */
export const ApiGetPaymentCheckUserInfo = (params) => get('/api/payment/checkUserInfo', params)

// ===================================供应商管理================================================

/**
 * @Author: wxz
 * @Date: 2024/1/3 14:05:47
 * @description 供应商列表页
 */
export const ApiGetSupplierManageListPage = (params) => get('/api/supplierManage/listPage', params)
/**
 * @Author: wxz
 * @Date: 2023/12/26 17:09:09
 * @description 查看供应商列表
 */
export const ApiGetSupplierManageList = (params) => get('/api/supplierManage/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/26 17:12:06
 * @description 供应商添加
 */
export const ApiPostSupplierManageAdd = (params, config = {}) => post('/api/supplierManage/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 17:09:55
 * @description 供应商编辑
 */
export const ApiPostSupplierManageEdit = (params, config = {}) => post('/api/supplierManage/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 17:10:24
 * @description 删除供应商
 */
export const ApiPostSupplierManageRemove = (params, config = {}) => post('/api/supplierManage/remove', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 17:10:45
 * @description 供应商详情
 */
export const ApiGetSupplierManageDetails = (params) => get('/api/supplierManage/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/26 17:10:59
 * @description 供应商导入
 */
export const ApiPostSupplierManageImportData = (params, config = {}) =>
  post('/api/supplierManage/importData', params, config)

// ===================================供应商管理-供应商产品================================================

/**
 * @Author: wxz
 * @Date: 2023/12/26 21:26:37
 * @description 查看供应商产品列表
 */
export const ApiGetSupplierProductList = (params) => get('/api/supplierProduct/list', params)
/**
 * @Author: wxz
 * @Date: 2023/12/26 21:25:17
 * @description 供应商产品添加
 */
export const ApiPostSupplierProductAdd = (params, config = {}) => post('/api/supplierProduct/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 21:25:34
 * @description 供应商产品编辑
 */
export const ApiPostSupplierProductEdit = (params, config = {}) => post('/api/supplierProduct/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 21:25:46
 * @description 删除供应商产品
 */
export const ApiPostSupplierProductRemove = (params, config = {}) => post('/api/supplierProduct/remove', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/26 22:10:57
 * @description 供应商产品详情
 */
export const ApiGetSupplierProductDetails = (params) => get('/api/supplierProduct/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/26 21:26:00
 * @description 供应商产品导入
 */
export const ApiPostSupplierProductImportData = (params, config = {}) =>
  request.post('/api/supplierProduct/importData', params, config)

// ===================================销货管理================================================

/**
 * @Author: wxz
 * @Date: 2024/1/10 20:58:02
 * @description 根据购货单位（供货商）和合同编码查询销售单商品
 */
export const ApiGetPurchasePurchaseList = (params) => get('/api/purchase/purchaseList', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 20:58:24
 * @description 通过销售详情ID 获取发起用户 审核用户 抄送用户信息
 */
export const ApiGetSalesCheckUserInfo = (params) => get('/api/sales/checkUserInfo', params)

// ====我提交的========

/**
 * @Author: wxz
 * @Date: 2024/1/10 20:59:04
 * @description 提交销售审批（json）
 */
export const ApiPostSalesAdd = (params, config = {}) => post('/api/sales/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 20:59:22
 * @description 我发起的审批列表
 */
export const ApiGetSalesMyInitiateListPage = (params) => get('/api/sales/myInitiateListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 20:59:42
 * @description 销售详情
 */
export const ApiGetSalesDetails = (params) => get('/api/sales/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:00:09
 * @description 我发起的销售撤回（只有在待审批的状态下才能撤回）
 */
export const ApiPostSalesInitiateRevocation = (params, config = {}) =>
  post('/api/sales/initiateRevocation', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:00:21
 * @description 修改销售审批(json) 只有在待审批或者已驳回或者已撤回的状态下才能修改
 */
export const ApiPostSalesUpdate = (params, config = {}) => post('/api/sales/update', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:00:31
 * @description 删除销售审批
 */
export const ApiPostSalesDeletes = (params, config = {}) => post('/api/sales/deletes', params, config)

// ====我审核的========

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:01:02
 * @description 我审批列表
 */
export const ApiGetSalesMyExamineListPage = (params) => get('/api/sales/myExamineListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:01:21
 * @description 我审批详情
 */
export const ApiGetSalesMyExamineDetails = (params) => get('/api/sales/myExamineDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:01:46
 * @description 我审批的审批提交 通过/拒绝
 */
export const ApiPostSalesExamineSub = (params, config = {}) => post('/api/sales/examineSub', params, config)

// ====抄送我的========

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:02:30
 * @description 抄送我的列表
 */
export const ApiGetSalesCopyMyListPage = (params) => get('/api/sales/copyMyListPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 21:02:50
 * @description 抄送我的详情
 */
export const ApiGetSalesCopyMyDetails = (params) => get('/api/sales/copyMyDetails', params)

// ===================================财务收款================================================

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:39:32
 * @description 添加其他付款
 */
export const ApiPostProceedsOtherAdd = (params, config = {}) => post('/api/proceeds/otherAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:38:15
 * @description 合同收款列表
 */
export const ApiGetProceedsList = (params) => get('/api/proceeds/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:39:11
 * @description 合同收款记录详情
 */
export const ApiGetProceedsDetails = (params) => get('/api/proceeds/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:39:51
 * @description 添加收款记录
 */
export const ApiPostProceedsAdd = (params, config = {}) => post('/api/proceeds/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:39:51
 * @description 删除收款记录
 */
export const ApiPostProceedsDel = (params, config = {}) => post('/api/proceeds/del', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/9 21:52:02
 * @description 收款审核
 */
export const ApiPostProceedsExamineSub = (params, config = {}) => post('/api/proceeds/examineSub', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:39:11
 * @description 收款记录审核人
 */
export const ApiGetProceedsCheckUserInfo = (params) => get('/api/proceeds/checkUserInfo', params)

/**
 * @Author: wxz
 * @Date: 2024/1/9 21:52:02
 * @description 修改收款记录
 */
export const ApiPostProceedsEdit = (params, config = {}) => post('/api/proceeds/edit', params, config)

// ===================================财务付款================================================

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:50:46
 * @description 财务付款列表
 */
export const ApiGetPaymentList = (params) => get('/api/payment/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:51:17
 * @description 合同付款记录详情
 */
export const ApiGetPaymentDetails = (params) => get('/api/payment/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:52:16
 * @description 添加付款记录
 */
export const ApiPostPaymentAdd = (params, config = {}) => post('/api/payment/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/10 17:39:51
 * @description 删除收款记录
 */
export const ApiPostPaymentDel = (params, config = {}) => post('/api/payment/del', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/9 21:34:49
 * @description 修改付款记录详情
 */
export const ApiPostPaymentEdit = (params, config = {}) => post('/api/payment/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:54:24
 * @description 根据用户查询客户付款记录详情
 */
export const ApiGetPaymentClientPayRecord = (params) => get('/api/payment/clientPayRecord', params)

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:53:08
 * @description 提交付款审核
 */
export const ApiGetPaymentSub = (params) => get('/api/payment/sub', params)

/**
 * @Author: wxz
 * @Date: 2024/1/9 21:52:02
 * @description 业务员审核
 */
export const ApiPostPaymentAudit = (params, config = {}) => post('/api/payment/audit', params, config)

// =========其他付款=========

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:56:26
 * @description 其他付款添加
 */
export const ApiPostPaymentOtherAdd = (params, config = {}) => post('/api/paymentOther/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/9 15:27:31
 * @description 删除
 */
export const ApiPostPaymentOtherDel = (params, config = {}) => post('/api/paymentOther/del', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/9 11:56:39
 * @description 其他付款列表
 */
export const ApiGetPaymentOtherList = (params) => get('/api/paymentOther/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/9 16:02:52
 * @description 确认付款
 */
export const ApiPostPaymentOtherAffirm = (params, config = {}) => post('/api/paymentOther/affirm', params, config)

// ===================================库房管理================================================

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:49:34
 * @description 库房列表（分页）
 */
export const ApiGetStoreroomListPage = (params) => get('/api/storeroom/listPage', params)
/**
 * @Author: wxz
 * @Date: 2023/12/27 22:49:48
 * @description 库房详情
 */
export const ApiGetStoreroomDetails = (params) => get('/api/storeroom/details', params)
/**
 * @Author: wxz
 * @Date: 2023/12/27 22:50:03
 * @description 新增库房(json)
 */
export const ApiPostStoreroomAdd = (params, config = {}) => post('/api/storeroom/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:50:16
 * @description 修改库房(json)
 */
export const ApiPostStoreroomUpdate = (params, config = {}) => post('/api/storeroom/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:50:57
 * @description 删除库房
 */
export const ApiPostStoreroomDeletes = (params, config = {}) => post('/api/storeroom/deletes', params, config)
/**
 * @Author: wxz
 * @Date: 2023/12/27 22:50:57
 * @description 库房导入
 */
export const ApiPostStoreroomStoreroomImport = (params, config = {}) => post('/api/storeroom/storeroomImport', params, config)
/**
 * @Author: wxz
 * @Date: 2023/12/27 22:50:57
 * @description 货位导入
 */
export const ApiPostStoreroomStockSpaceImport = (params, config = {}) => post('/api/storeroom/stockSpaceImport', params, config)
/**
 * @Author: wxz
 * @Date: 2023/12/27 22:51:17
 * @description 货位列表（无分页）
 */
export const ApiGetStoreroomStockSpaceList = (params) => get('/api/storeroom/stockSpaceList', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:51:43
 * @description 货位详情
 */
export const ApiGetStoreroomStockSpaceDetails = (params) => get('/api/storeroom/stockSpaceDetails', params)

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:51:30
 * @description 货位新增
 */
export const ApiPostStoreroomStockSpaceAdd = (params, config = {}) =>
  post('/api/storeroom/stockSpaceAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:52:01
 * @description 货位修改
 */
export const ApiPostStoreroomStockSpaceUpdate = (params, config = {}) =>
  post('/api/storeroom/stockSpaceUpdate', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 22:52:13
 * @description 货位删除
 */
export const ApiPostStoreroomStockSpaceDeletes = (params, config = {}) =>
  post('/api/storeroom/stockSpaceDeletes', params, config)

// ===================================报备库存================================================

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:49:37
 * @description  报备库存列表
 */
export const ApiGetReportStockList = (params) => get('/api/reportStock/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:50:43
 * @description 添加报备
 */
export const ApiPostReportStockAdd = (params, config = {}) => post('/api/reportStock/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:50:43
 * @description 直接解绑，无需审核
 */
export const ApiPostReportStockUnbind = (params, config = {}) => post('/api/reportStock/unbind', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:50:55
 * @description 提交审核解绑 submit
 */
export const ApiPostReportStockSubmit = (params, config = {}) => post('/api/reportStock/submit', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:51:05
 * @description 审核
 */
export const ApiPostReportStockAudit = (params, config = {}) => post('/api/reportStock/audit', params, config)

// ===================================入库管理================================================

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:28:25
 * @description 入库明细列表
 */
export const ApiGetWarehouseDetailList = (params) => get('/api/warehouseDetail/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:28:39
 * @description 添加采购入库明细/退换货入库明细
 */
export const ApiPostWarehouseDetailAdd = (params, config = {}) => post('/api/warehouseDetail/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:28:39
 * @description 新增入库导入
 */
export const ApiPostWarehouseAddImportData = (params, config = {}) => post('/api/warehouseDetail/addImportData', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:28:55
 * @description 供应商商品列表
 */
export const ApiGetWarehouseDetailPurchasesList = (params) => get('/api/warehouseDetail/purchasesList', params)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:29:05
 * @description 退换货 合同相关产品列表
 */
export const ApiGetWarehouseDetailContractList = (params) => get('/api/warehouseDetail/contractList', params)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:29:16
 * @description 确认入库
 */
export const ApiGetWarehouseDetailAffirm = (params, config = {}) => post('/api/warehouseDetail/affirm', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:29:16
 * @description 拆分入库
 */
export const ApiGetWarehouseDetailSplitWarehouseRecord = (params, config = {}) =>
  post('/api/warehouseDetail/splitWarehouseRecord', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/28 16:29:16
 * @description 导出入库
 */
export const ApiGetWarehouseDetailSplitExport = (params, config = {}) =>
  post('/api/warehouseDetail/export', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:36:58
 * @description 导入入库
 */
export const ApiPostWarehouseDetailImportData = (params, config = {}) =>
  post('/api/warehouseDetail/importData', params, config)

// ===================================产品库存 销售历史================================================

/**
 * @Author: wxz
 * @Date: 2023/12/30 10:14:45
 * @description 获取销售历史
 */
export const ApiGetSalesHistory = (params) => get('/api/sales/salesHistory', params)

// ===================================产品库存================================================

/**
 * @Author: wxz
 * @Date: 2023/12/30 10:14:45
 * @description 添加库存分类
 */
export const ApiGetStockUpdate = (params) => get('/api/stock/update', params)

/**
 * @Author: wxz
 * @Date: 2023/12/30 10:16:18
 * @description 设置预售预警
 */
export const ApiGetStockPresell = (params) => get('/api/stock/presell', params)

/**
 * @Author: wxz
 * @Date: 2023/12/30 10:16:29
 * @description 查看产品库存列表
 */
export const ApiGetStockList = (params) => get('/api/stock/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/30 10:16:40
 * @description 产品库存导入
 */
export const ApiPostStockImportData = (params, config = {}) => post('/api/stock/importData', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/30 19:29:14
 * @description 成套产品详情
 */
export const ApiGetStockWholeDetails = (params) => get('/api/stock/wholeDetails', params)

/**
 * @Author: wxz
 * @Date: 2023/12/30 19:29:28
 * @description 组装成套产品
 */
export const ApiPostStockWholeAdd = (params, config = {}) => post('/api/stock/wholeAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/30 19:29:44
 * @description 成套产品编辑
 */
export const ApiPostStockWholeEdit = (params, config = {}) => post('/api/stock/wholeEdit', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/30 10:16:04
 * @description 单体商品列表查询(组成套)
 */
export const ApiGetStockStockList = (params) => get('/api/stock/stockList', params)

// ===================================调拨管理================================================

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:48:46
 * @description 调拨列表（我提交的）
 */
export const ApiGetAllotList = (params) => get('/api/allot/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:48:46
 * @description 调拨列表 我审批列表
 */
export const ApiGetAllotMyExamineListPage = (params) => get('/api/allot/myExamineListPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:48:46
 * @description 调拨列表 抄送我的
 */
export const ApiGetAllotCopyMyListPage = (params) => get('/api/allot/copyMyListPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:49:29
 * @description 调拨详情（我提交的）
 */
export const ApiGetAllotDetails = (params) => get('/api/allot/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:49:29
 * @description 调拨详情 我审批详情
 */
export const ApiGetAllotMyExamineDetails = (params) => get('/api/allot/myExamineDetails', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:49:29
 * @description 抄送我的详情
 */
export const ApiGetAllotCopyMyDetails = (params) => get('/api/allot/copyMyDetails', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:49:29
 * @description 调拨详情获取审核人
 */
export const ApiGetAllotCheckUserInfo = (params) => get('/api/allot/checkUserInfo', params)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:49:54
 * @description 调拨添加
 */
export const ApiPostAllotAdd = (params, config = {}) => post('/api/allot/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:49:54
 * @description 调拨重新提交
 */
export const ApiPostAllotEdit = (params, config = {}) => post('/api/allot/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:50:06
 * @description 调拨审核
 */
export const ApiPostAllotAudit = (params, config = {}) => post('/api/allot/audit', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/31 10:50:22
 * @description 查询某库所有产品列表（调拨产品选择，出库产品选择）
 */
export const ApiGetAllotStoreList = (params) => get('/api/allot/storeList', params)

// ===================================出库管理================================================

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:23:11
 * @description 出库列表
 */
export const ApiGetLeaveStoreroomList = (params) => get('/api/leaveStoreroom/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/12 21:38:37
 * @description 销售出库详情
 */
export const ApiGetLeaveStoreroomDetails = (params) => get('/api/leaveStoreroom/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:23:48
 * @description 添加出库记录
 */
export const ApiPostLeaveStoreroomAdd = (params, config = {}) => post('/api/leaveStoreroom/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:23:48
 * @description 修改出库记录
 */
export const ApiPostLeaveStoreroomEdit = (params, config = {}) => post('/api/leaveStoreroom/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/11 16:59:22
 * @description  确认出库 添加入库记录  出库凭证 运输方式
 */
export const ApiPostAllotAffirm = (params, config = {}) => post('/api/allot/affirm', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/7 15:24:31
 * @description 上传签收回执，点击确认签收，记录签收日期→状态：已签收 仅针对销售出库
 */
export const ApiPostLeaveStoreroomSign = (params, config = {}) => post('/api/leaveStoreroom/sign', params, config)

// ===================================物资库管理================================================

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:36:24
 * @description 资料库列表
 */
export const ApiGetDataBankList = (params) => get('/api/dataBank/list', params)

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:36:45
 * @description 资料库添加
 */
export const ApiPostDataBankAdd = (params, config = {}) => post('/api/dataBank/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:36:58
 * @description 资料库导入
 */
export const ApiPostDataBankImportData = (params, config = {}) => post('/api/dataBank/importData', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:37:08
 * @description 资料库修改
 */
export const ApiPostDataBankEdit = (params, config = {}) => post('/api/dataBank/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/15 11:37:18
 * @description 资料库删除
 */
export const ApiPostDataBankDel = (params, config = {}) => post('/api/dataBank/del', params, config)

// ===================================会议管理================================================

/**
 * @Author: wxz
 * @Date: 2023/12/14 14:32:55
 * @description 会议列表
 */
export const ApiGetTrainingList = (params) => get('/api/training/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/14 14:33:12
 * @description 会议详情
 */
export const ApiGetTrainingDetails = (params) => get('/api/training/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/14 14:33:22
 * @description 会议添加
 */
export const ApiPostTrainingAdd = (params, config = {}) => post('/api/training/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/14 14:33:33
 * @description 会议纪要添加
 */
export const ApiPostTrainingMeetingAdd = (params, config = {}) => post('/api/training/meetingAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/14 14:33:41
 * @description 领导评价
 */
export const ApiPostTrainingEvaluateAdd = (params, config = {}) => post('/api/training/evaluateAdd', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/27 17:13:25
 * @description 取消会议
 */
export const ApiPostTrainingCancel = (params, config = {}) => post('/api/training/cancel', params, config)

// ===================================企业文化-圈子================================================

/**
 * @Author: wxz
 * @Date: 20:46:32
 * @description 获取圈子列表（分页）
 */
export const ApiGetCircleNaNistPage = (params) => get('/api/circle/listPage', params)
/**
 * @Author: wxz
 * @Date: 20:46:32
 * @description 获取我的圈子列表（分页）
 */
export const ApiGetCircleMyListPage = (params) => get('/api/circle/myListPage', params)
/**
 * @Author: wxz
 * @Date: 20:46:43
 * @description 获取圈子详情
 */
export const ApiGetCircleNaNetails = (params) => get('/api/circle/details', params)
/**
 * @Author: wxz
 * @Date: 20:46:50
 * @description 新增圈子
 */
export const ApiPostCircleAdd = (params, config = {}) => post('/api/circle/add', params, config)
/**
 * @Author: wxz
 * @Date: 20:46:58
 * @description 修改圈子
 */
export const ApiPostCircleUpdate = (params, config = {}) => post('/api/circle/update', params, config)
/**
 * @Author: wxz
 * @Date: 20:47:07
 * @description 批量删除圈子
 */
export const ApiPostCircleDeletes = (params, config = {}) => post('/api/circle/deletes', params, config)

// ===================================企业文化-动态================================================

/**
 * @Author: wxz
 * @Date: 2023/12/9 11:47:41
 * @description 获取动态列表（分页）
 */
export const ApiGetDynamicListPage = (params) => get('/api/dynamic/listPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:22:47
 * @description 获取我的动态列表（分页）
 */
export const ApiGetDynamicMyListPage = (params) => get('/api/dynamic/myListPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/9 11:47:52
 * @description 获取动态详情
 */
export const ApiGetDynamicDetails = (params) => get('/api/dynamic/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/9 11:48:05
 * @description 新增动态
 */
export const ApiPostDynamicAdd = (params, config = {}) => post('/api/dynamic/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/9 11:48:14
 * @description 修改动态
 */
export const ApiPostDynamicUpdate = (params, config = {}) => post('/api/dynamic/update', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/9 11:48:25
 * @description 批量删除动态
 */
export const ApiPostDynamicDeletes = (params, config = {}) => post('/api/dynamic/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/11 13:52:48
 * @description 添加评论
 */
export const ApiPostCommentRecordAdd = (params, config = {}) => post('/api/commentRecord/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/11 13:53:00
 * @description 评论列表
 */
export const ApiGetCommentRecordListPage = (params) => get('/api/commentRecord/listPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/11 13:53:15
 * @description 评论回复列表
 */
export const ApiGetCommentRecordReplyList = (params) => get('/api/commentRecord/replyList', params)
/**
 * @Author: wxz
 * @Date: 2023/12/11 13:53:23
 * @description 删除评论
 */
export const ApiPostCommentRecordDelete = (params, config = {}) => post('/api/commentRecord/delete', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/12 17:01:55
 * @description 点赞/取消点赞
 */
export const ApiPostLikeLike = (params, config = {}) => post('/api/like/like', params, config)

// ===================================企业文化-团建================================================

/**
 * @Author: wxz
 * @Date: 2023/12/12 19:52:04
 * @description 获取公司团建列表（分页）
 */
export const ApiGetTeamBuildListPage = (params) => get('/api/teamBuild/listPage', params)
/**
 * @Author: wxz
 * @Date: 2023/12/12 19:52:20
 * @description 获取公司团建详情
 */
export const ApiGetTeamBuildDetails = (params) => get('/api/teamBuild/details', params)
/**
 * @Author: wxz
 * @Date: 2023/12/12 19:52:33
 * @description 新增公司团建
 */
export const ApiPostTeamBuildAdd = (params, config = {}) => post('/api/teamBuild/add', params, config)
/**
 * @Author: wxz
 * @Date: 2023/12/12 19:52:42
 * @description 修改公司团建
 */
export const ApiPostTeamBuildUpdate = (params, config = {}) => post('/api/teamBuild/update', params, config)
/**
 * @Author: wxz
 * @Date: 2023/12/12 19:52:53
 * @description 删除公司团建
 */
export const ApiPostTeamBuildDeletes = (params, config = {}) => post('/api/teamBuild/deletes', params, config)

// ===================================资料共享库-资料文件================================================

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:00:44
 * @description 获取资源分类列表（无分页）
 */
export const ApiGetResourceClassList = (params) => get('/api/resourceClass/list', params)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:01:13
 * @description 获取资源文件列表（分页）
 */
export const ApiGetResourceFileListPage = (params) => get('/api/resourceFile/listPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:01:30
 * @description 获取资源文件详情
 */
export const ApiGetResourceFileDetails = (params) => get('/api/resourceFile/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:01:39
 * @description 新增资源文件
 */
export const ApiPostResourceFileAdd = (params, config = {}) => post('/api/resourceFile/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:01:48
 * @description 批量删除资源文件
 */
export const ApiPostResourceFileDeletes = (params, config = {}) => post('/api/resourceFile/deletes', params, config)

// ===================================资料共享库-标书管理================================================

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:03:40
 * @description 获取标书文件列表（分页）
 */
export const ApiGetBidDocumentListPage = (params) => get('/api/bidDocument/listPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:04:02
 * @description 获取标书文件详情
 */
export const ApiGetBidDocumentDetails = (params) => get('/api/bidDocument/details', params)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:04:31
 * @description 新增标书文件
 */
export const ApiPostBidDocumentAdd = (params, config = {}) => post('/api/bidDocument/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:04:47
 * @description 批量删除标书文件
 */
export const ApiPostBidDocumentDeletes = (params, config = {}) => post('/api/bidDocument/deletes', params, config)

// ===================================资料共享库-外部链接================================================

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:05:02
 * @description 获取外部链接列表（分页）
 */
export const ApiGetExternalLinkListPage = (params) => get('/api/externalLink/listPage', params)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:06:06
 * @description 新增外部链接详情
 */
export const ApiPostExternalLinkAdd = (params, config = {}) => post('/api/externalLink/add', params, config)

/**
 * @Author: wxz
 * @Date: 2023/12/13 17:06:22
 * @description 批量删除外部链接
 */
export const ApiPostExternalLinkDeletes = (params, config = {}) => post('/api/externalLink/deletes', params, config)

// ===================================消息================================================

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:50:26
 * @description 消息列表
 */
export const ApiGetMessageRecordListPage = (params) => get('/api/messageRecord/listPage', params)

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:50:44
 * @description 消息详情
 */
export const ApiGetMessageRecordDetails = (params) => get('/api/messageRecord/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:51:00
 * @description 消息全部已读
 */
export const ApiPostMessageRecordAllRead = (params, config = {}) => post('/api/messageRecord/allRead', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:51:17
 * @description 消息删除已读
 */
export const ApiPostMessageRecordDeleteAllRead = (params, config = {}) =>
  post('/api/messageRecord/deleteAllRead', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:51:36
 * @description 删除信息
 */
export const ApiPostMessageRecordDeletes = (params, config = {}) => post('/api/messageRecord/deletes', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/16 15:51:53
 * @description 未读消息统计
 */
export const ApiGetMessageRecordUnreadCount = (params) => get('/api/messageRecord/unreadCount', params)

// ==============公告==================

/**
 * @Author: wxz
 * @Date: 2024/1/13 13:07:24
 * @description 新增通知公告
 */
export const ApiPostNoticeAdd = (params, config = {}) => post('/api/notice/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/13 13:07:55
 * @description 通知公告列表
 */
export const ApiGetNoticeList = (params) => get('/api/notice/list', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 13:08:16
 * @description 通知公告详情
 */
export const ApiGetNoticeDetails = (params) => get('/api/notice/details', params)

/**
 * @Author: wxz
 * @Date: 2024/1/13 13:08:38
 * @description 删除通知公告
 */
export const ApiPostNoticeDelete = (params, config = {}) => post('/api/notice/delete', params, config)

/**
 * @Author: wxz
 * @Date: 2024/1/13 13:08:51
 * @description 修改通知公告
 */
export const ApiPostNoticeUpdate = (params, config = {}) => post('/api/notice/update', params, config)

// ===================================系统管理相关================================================
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  获取菜单权限列表
 */
export const roleMenuTreeData = (params, config = {}) => get('/api/role/roleMenuTreeData', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  获取获取角色数据权限树
 */
export const roleDeptTreeData = (params, config = {}) => get('/api/role/deptTreeData', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  保存角色分配数据权限
 */
export const roleAuthDataScope = (params, config = {}) => post('/api/role/authDataScope', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  角色列表
 */
export const roleList = (params, config = {}) => get('/api/role/list', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  角色添加
 */
export const addRole = (params, config = {}) => post('/api/role/addRole', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  角色修改
 */
export const editRole = (params, config = {}) => post('/api/role/editRole', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-12 10:45:56
 * @Desc:  角色删除
 */
export const removeRole = (params, config = {}) => post('/api/role/removeRole', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  日志列表
 */
export const logininforLog = (params, config = {}) => post('/api/operlog/list', params, config)

// ===================================考勤管理===============================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 获取公司打卡统计
 */
export const clockingStatisticsList = (params, config = {}) =>
  get('/api/clocking/clockingStatisticsList', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 获取公司某人打卡统计
 */
export const getClockingRecordByUserId = (params, config = {}) =>
  get('/api/clocking/getClockingRecordByUserId', params, config)

// ===================================公司管理===============================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 获取公司列表(分页)
 */
export const companyListPage = (params, config = {}) => get('/api/company/listPage', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 获取公司列表(分页)不带权限
 */
export const companyCompanyList = (params, config = {}) => get('/api/company/companyList', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 获取公司详情
 */
export const companyDetails = (params, config = {}) => get('/api/company/details', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  新增公司
 */
export const companyAdd = (params, config = {}) => post('/api/company/add', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  修改公司
 */
export const companyUpdate = (params, config = {}) => post('/api/company/update', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  删除公司
 */
export const companyDeletes = (params, config = {}) => post('/api/company/deletes', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  设置公司考勤
 */
export const companyUpdateChecking = (params, config = {}) => post('/api/company/updateChecking', params, config)

// ===================================部门管理===============================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 部门列表（分页）
 */
export const deptListPage = (params, config = {}) => get('/api/dept/listPage', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  添加部门
 */
export const deptAdd = (params, config = {}) => post('/api/dept/add', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  修改部门
 */
export const deptUpdate = (params, config = {}) => post('/api/dept/update', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  删除部门
 */
export const deptDeletes = (params, config = {}) => post('/api/dept/deletes', params, config)

// ===================================岗位管理===============================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 岗位列表（分页）
 */
export const postListPage = (params, config = {}) => get('/api/post/listPage', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  添加岗位
 */
export const postAdd = (params, config = {}) => post('/api/post/add', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  修改岗位
 */
export const postUpdate = (params, config = {}) => post('/api/post/update', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  删除岗位
 */
export const postDeletes = (params, config = {}) => post('/api/post/deletes', params, config)

// ===================================人员（用户）管理===============================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 人员列表（分页）
 */
export const userListPage = (params, config = {}) => get('/api/user/list', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-05 17:11:38
 * @Desc: 人员详情
 */
export const userDetails = (params, config = {}) => get('/api/user/details', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  添加人员
 */
export const userAdd = (params, config = {}) => post('/api/user/addUser', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  修改人员
 */
export const userEditUser = (params, config = {}) => post('/api/user/editUser', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  删除人员
 */
export const userRemoveUser = (params, config = {}) => post('/api/user/remove', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-13 17:44:09
 * @Desc:  修改用户密码
 */
export const userresetPwd = (params, config = {}) => post('/api/user/resetPwd', params, config)

// ===================================字典管理===============================================

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-08 17:17:26
 * @Desc:  字典数据类型列表
 */
export const dictList = (params, config = {}) => post('/api/dict/list', params, config)
/**
 * @Author: wxz
 * @Date: 18:05:38
 * @description 新增保存字典类型
 */
export const ApiPostDictAdd = (params, config = {}) => post('/api/dict/add', params, config)
/**
 * @Author: wxz
 * @Date: 18:06:03
 * @description 修改保存字典类型
 */
export const ApiPostDictEdit = (params, config = {}) => post('/api/dict/edit', params, config)
/**
 * @Author: wxz
 * @Date: 18:06:11
 * @description 删除字典类型
 */
export const ApiPostDictRemove = (params, config = {}) => post('/api/dict/remove', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-08 17:17:26
 * @Desc:  字典数据列表
 */
export const dictDataList = (params, config = {}) => post('/api/dict/data/list', params, config)
/**
 * @Author: wxz
 * @Date: 19:17:46
 * @description 新增保存字典类型
 */
export const ApiPostDictDataAdd = (params, config = {}) => post('/api/dict/data/add', params, config)
/**
 * @Author: wxz
 * @Date: 19:18:31
 * @description 修改保存字典类型
 */
export const ApiPostDictDataEdit = (params, config = {}) => post('/api/dict/data/edit', params, config)
/**
 * @Author: wxz
 * @Date: 19:19:08
 * @description 删除字典数据
 */
export const ApiPostDictDataRemove = (params, config = {}) => post('/api/dict/data/remove', params, config)

// ============个人中i心=============

/**
 * @Author: wxz
 * @Date: 2024/1/20 10:22:50
 * @description 个人中心统计
 */
export const ApiGetCircleStatistics = (params) => get('/api/circle/statistics', params)

// ============工作计划=============

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:28:03
 * @description 周报列表（我提交的）
 */
export const ApiGetWeekRecordNaNubmitList = (params) => get('/api/weekRecord/submitList', params)

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:28:55
 * @description 周报添加
 */
export const ApiPostWeekRecordAdd = (params, config = {}) => post('/api/weekRecord/add', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:30:10
 * @description 周报详情（我提交的）
 */
export const ApiGetWeekRecordNaNetails = (params) => get('/api/weekRecord/details', params)

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:31:00
 * @description 周报列表（抄送我的）
 */
export const ApiGetWeekRecordNaNopyList = (params) => get('/api/weekRecord/copyList', params)

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:31:30
 * @description 周报详情（抄送我的）
 */
export const ApiGetWeekRecordNaNopyDetails = (params) => get('/api/weekRecord/copyDetails', params)

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:32:07
 * @description 周报修改
 */
export const ApiPostWeekRecordEdit = (params, config = {}) => post('/api/weekRecord/edit', params, config)

/**
 * @Author: wxz
 * @Date: 2024/3/29 16:32:38
 * @description 周报ID 获取发起用户 抄送用户信息
 */
export const ApiPostWeekRecordCheckUserInfo = (params, config = {}) =>
  get('/api/weekRecord/checkUserInfo', params, config)
